<template>
  <div class="page">
    <section>
      <!-- <el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/personalCenter' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>设置</el-breadcrumb-item>
			</el-breadcrumb> -->
      <section>
        <!--  <nav>
          <el-menu
            :default-active="ComponentsIndex"
            active-text-color="#03DBC6"
            @select="seleasd($event)"
          >
            <template v-for="(navItem, navIndex) in navList">
              <el-menu-item :key="navIndex" :index="`${navIndex}`">
                <span slot="title">{{ navItem.label }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </nav> -->
        <div class="content">
          <keep-alive>
            <component :is="personalCenterComponents"></component>
          </keep-alive>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

// 批量引入components/setting目录下的所有组件
const components = require
  .context("@components/setting/", false, /.*\.vue/)
  .keys()
  .reduce((components, componentsPath) => {
    const componentsName = componentsPath.replace(/\.\/(.*)\.vue/, "$1");
    components[componentsName] =
      require(`@components/setting/${componentsName}`).default;
    return components;
  }, {});

export default {
  components,
  data() {
    return {
      navList: [
        // { label: "编辑资料", components: "editInfo" },
        // { label: "收货地址", components: "" },
        // { label: "用户协议", components: "userAgreement" },
        {
          label: "隐私服务",
          components: "privacyService",
        },
        // { label: "个税代缴代付协议", components: "agreement" },
        // { label: "举报须知", components: "report" },
      ],
      ComponentsIndex: "0",
    };
  },
  watch: {
    $route(val) {
      let index = val.query.index;

      if (index) {
        this.ComponentsIndex = index;
      }
    },
    ComponentsIndex(val) {
      // this.$route.query.index = val;
      const newUrl = `#/setting?index=${val}`;
      window.history.replaceState("", "", newUrl);
    },
  },
  created() {
    let index = this.$route.query.index;
    if (index) {
      this.ComponentsIndex = index;
    }
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    personalCenterComponents() {
      return this.navList[this.ComponentsIndex.split("-")[0]].childList
        ? this.navList[this.ComponentsIndex.split("-")[0]].childList[
            this.ComponentsIndex.split("-")[1]
          ].components
        : this.navList[this.ComponentsIndex.split("-")[0]].components;
    },
  },

  methods: {
    seleasd(e) {
      window.scrollTo(0, 0);
      if (e == 0)
        if (localStorage.token) {
          this.ComponentsIndex = e;
        } else this.xin.message("请先登录", "err");
      else this.ComponentsIndex = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f2f2f2;

  > section {
    > section {
      margin-top: 30px;
      display: flex;

      > nav {
        width: 196px;
        height: max-content;
        background-color: white;
        position: sticky;
        top: 100px;

        ::v-deep {
          .el-menu {
            .el-menu--inline {
              overflow: hidden;

              .el-menu-item {
                padding-left: 50px !important;
              }
            }

            .el-menu-item,
            .el-submenu {
              span {
                margin-left: 10px;
              }
            }
          }

          .is-active {
            &::before {
              position: absolute;
              left: 0;
              top: calc((100% - 35px) / 2);
              content: "";
              display: block;
              width: 5px;
              height: 35px;
              background-color: $--themeColor;
            }
          }
        }
      }

      > .content {
        flex: 1;
        margin-left: 20px;
        // background-color: white;
        height: max-content;
      }
    }
  }
}

header {
  display: none !important;
}

main > header > .suspension {
  display: none;
  background-color: red;
}
/deep/.suspension {
  display: none;
}
/deep/.header-box {
  display: none !important;
}
</style>
